/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_home': {
      init: function() {
        // JavaScript to be fired on the home page
        function fullscreen(){
                var screenHeight = jQuery(window).height() - 135; 
                jQuery('.hero-full-screen').css({
                    width: jQuery(window).width(),
                    height: screenHeight
                });
            }
          
            fullscreen();
        jQuery(document).ready(function($){
          $("#clickwhy").click(function(){
            console.log('here');
            var iframe = $("#whyvimeo");
            console.log(iframe);
            iframe.attr("src", iframe.data("src")); 
          });
         // Defining a function to set size for #hero             

          // Run the function in case of window resize
          jQuery(window).resize(function() {
               fullscreen();         
            });
            function heightScreen(){
                var screenHeight = jQuery(window).height() - 134; 
                jQuery('#about').css({
                    minHeight: screenHeight
                });
            }
          
            heightScreen();

          // Run the function in case of window resize
          jQuery(window).resize(function() {
               heightScreen();         
            }); 

            jQuery('#why_video').on({
    'hide.uk.modal': function(){
        jQuery('#whyvimeo').attr("src","");
    }
});    

        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_registration': {
      init: function() {
        // JavaScript to be fired on the home page
        jQuery( document ).ready(function() {

function getTimeRemaining(endtime) {
  var t = Date.parse(endtime) - Date.parse(new Date());
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function initializeClock(id, endtime) {
  var clock = document.getElementById(id);

  var daysSpan = clock.querySelector('.days');
  var hoursSpan = clock.querySelector('.hours');
  var minutesSpan = clock.querySelector('.minutes');
  var secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    var t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  var timeinterval = setInterval(updateClock, 1000);
}

var deadline = '2016-7-25T12:00:00';
initializeClock('clockdiv1', deadline);
initializeClock('clockdiv2', deadline);
initializeClock('clockdiv3', deadline);
});

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_ideas': {
      init: function() {
        // JavaScript to be fired on the about us page
        jQuery(document).ready(function($){
         // Defining a function to set size for #hero 
            function fullscreen(){
                var screenHeight = jQuery(window).height() - 135; 
                jQuery('#hero-ps').css({
                    width: jQuery(window).width(),
                    height: screenHeight
                });
            }
          
            fullscreen();

          // Run the function in case of window resize
          jQuery(window).resize(function() {
               fullscreen();         
            });

           $(".clickidea").click(function(){
            console.log('here');
            var iframe = $("#ideavideo");
            var source = $(this).data("video") + "?autoplay=1";
            console.log(source);
            iframe.attr("src", source); 
          });           
        });
        jQuery('#idea_video').on({
          'hide.uk.modal': function(){
        jQuery('#ideavideo').attr("src","");
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
